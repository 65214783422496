import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import { getSingleOrder } from '../../../../actions/shopActions';

import { getUrlParams, formatDate } from '../../../../utils/stringOperations';

import { STATUS_COLORS } from '../../../../constants/colors';

import imgDelivery from '../../../../assets/img/order/delivery/order_delivery_shipped.png';

import './delivery.styl';

class OrderDelivery extends Component {
  state = {
    order: {},
  };

  componentDidMount() {
    const { params, token, getSingleOrder } = this.props;

    const paramsFormat = getUrlParams(params);
    if (!paramsFormat.cmdId) {
      navigate('account/orders');
    }
    getSingleOrder(token, paramsFormat.cmdId).then(order => {
      if (order !== false) {
        this.setState({ order });
      }
    });
  }

  renderBlock(title, description, color = null) {
    return (
      <div className="info-column">
        <p className="info-title">{title}</p>
        <p style={{ color }}>{description}</p>
      </div>
    );
  }

  render() {
    const { order } = this.state;
    const { wordings } = this.props;
    console.log(STATUS_COLORS);
    const isOrderFilled = Object.keys(order).length;
    const FIVE_DAYS = 60 * 60 * 24 * 5;
    const date = formatDate((order.created_at + FIVE_DAYS) * 1000, true);
    const title = `${wordings.orders.delivery_shipping} ${date.day} ${
      wordings.date[date.month]
    }`;
    const status = {
      wording: `delivery_status_${order.status}`,
      color: STATUS_COLORS[order.status],
    };

    return (
      isOrderFilled && (
        <section className="order-delivery-wrapper">
          <div className="title-wrapper">
            <h1>{title}</h1>
          </div>
          <div className="delivery-wrapper">
            <img
              src={imgDelivery}
              alt="delivery status"
              className="icon-shipped"
            />
            <div className="separator" />

            <div className="info-wrapper">
              {/* {this.renderBlock(
                wordings.orders.delivery_following_number_title,
                order.shipping_tracking_number
              )} */}
              {this.renderBlock(
                wordings.orders.delivery_carrier_title,
                order.shipping_method
              )}
              {this.renderBlock(
                wordings.orders.delivery_status_title,
                wordings.orders[status.wording],
                status.color
              )}
            </div>

            <div className="separator" />
          </div>
        </section>
      )
    );
  }
}

OrderDelivery.propTypes = {
  wordings: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
};

const mapStateToProps = ({ account: { access_token } }) => ({
  token: access_token,
});

export default connect(
  mapStateToProps,
  { getSingleOrder }
)(OrderDelivery);
