import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import {
  NavBar,
  Menu,
  Footer,
  Basket,
  AccountNav,
} from '../../../components/common';

import OrderDelivery from '../../../components/account/orders/delivery/orderDelivery';
import Layout from '../../../components/layout/layout';

class OrderDeliveryPage extends Component {
  render() {
    const {
      data: {
        wordings: {
          fr: { orders, profile, date },
        },
      },
      location: { search },
    } = this.props;
    const deliveryWordings = {
      orders,
      date,
    };
    return (
      <Layout title={orders.page_title} description={orders.description}>
        <NavBar withLogo basketClassName="yellow" />
        <Basket />
        <Menu />
        <AccountNav wordings={profile} active="account_orders">
          <OrderDelivery wordings={deliveryWordings} params={search} />
        </AccountNav>
        <Footer />
      </Layout>
    );
  }
}

OrderDeliveryPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export const query = graphql`
  query OrderDeliveryPageQuery {
    wordings {
      fr {
        orders {
          page_title
          description
          delivery_shipping
          delivery_following_number_title
          delivery_carrier_title
          delivery_status_title
          delivery_status_PENDING
          delivery_status_PROCESSING
          delivery_status_PAYMENT_PENDING
          delivery_status_PAYMENT_DUE
          delivery_status_PAID
          delivery_status_PREPPING
          delivery_status_SHIPPED
          delivery_status_COMPLETED
        }
        profile {
          profile_link
          orders_link
        }
        date {
          month_january
          month_february
          month_march
          month_april
          month_may
          month_june
          month_july
          month_august
          month_september
          month_october
          month_november
          month_december
        }
      }
    }
  }
`;

export default OrderDeliveryPage;
